import gql from 'graphql-tag';
import { print } from 'graphql';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, IPrismicObject, linkFragment } from '.';
import { IImage, ILink } from 'utils/types';
import { PrismicImage, PrismicLink } from './utils/PrismicTypes';
import { generateSliceFragments, IBaseSlice, PageTypes, parseSlices } from './slices';
import {
  parseImage,
  parseLink,
  parseString,
  parseRichText,
  parseLinkFromMeta,
} from './utils/parsers';

export interface IInvestorInformation extends IPrismicObject {
  uid: string;
  title: string;
  annualReport: ILink;
  months: Array<{
    month: string;
    consolidated: string;
    yoyChange: string;
    ytd: string;
    yoyTotal: string;
  }>;
  quarters: Array<{
    quarter: string;
    consolidated: ILink;
    press: ILink;
    earnings: ILink;
    webcast: ILink;
  }>;
  documents?: Array<{
    title: string;
    link: ILink;
  }>;
}

export type PrismicInvestorInformation = {
  allInvestor_informations: {
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        title: RichTextBlock[];
        annual_report: PrismicLink;
        monthly: Array<{
          item_month: string;
          item_consolidated: RichTextBlock[];
          item_yoychange: RichTextBlock[];
          item_ytd: RichTextBlock[];
          item_yoytotal: RichTextBlock[];
        }>;
        quarters: Array<{
          item_quarter: string;
          item_consolidated: PrismicLink;
          item_press: PrismicLink;
          item_earnings: PrismicLink;
          item_webcast: PrismicLink;
        }>;
        documents: Array<{
          item_title: string;
          item_link: PrismicLink;
        }>;
        _meta: {
          uid: string;
          type: string;
          lang: string;
          tags: string[];
        };
      };
    }>;
  };
};

export const fetchInvestorInformations = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number
): Promise<PrismicInvestorInformation> => {
  return await fetchAPI(
    print(gql`
      query LastesInvestorInformations($lang: String!, $endCursor: String, $limit: Int) {
        allInvestor_informations(
          lang: $lang
          sortBy: title_DESC
          first: $limit
          after: $endCursor
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              title
              annual_report {
                ...Link
              }

              monthly {
                item_month
                item_consolidated
                item_yoychange
                item_ytd
                item_yoytotal
              }

              quarters {
                item_quarter
                item_consolidated {
                  ...Link
                }
                item_press {
                  ...Link
                }
                item_earnings {
                  ...Link
                }
                item_webcast {
                  ...Link
                }
              }

              documents {
                item_title
                item_link {
                  ...Link
                }
              }

              _meta {
                uid
                type
                lang
                tags
              }
            }
          }
        }
      }
      ${linkFragment}
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
      },
    }
  );
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
const fetchAllInvestorInformations = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number
): Promise<PrismicInvestorInformation> => {
  const data = await fetchInvestorInformations(lang, endCursor, previewData, limit);

  if (
    data.allInvestor_informations.pageInfo.hasNextPage &&
    data.allInvestor_informations.edges.length < limit
  ) {
    const nestedList = await fetchAllInvestorInformations(
      lang,
      data.allInvestor_informations.pageInfo.endCursor,
      previewData,
      limit
    );
    return {
      allInvestor_informations: {
        edges: [
          ...data.allInvestor_informations.edges,
          ...nestedList.allInvestor_informations.edges,
        ],
      },
    };
  }
  return data;
};

export const getAllInvestorInformations = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  redirects = [],
} = {}): Promise<IInvestorInformation[]> => {
  const items = await fetchAllInvestorInformations(lang, '', previewData, limit);

  return parseInvestorInformations(items, redirects);
};

export const parseInvestorInformations = (
  items: PrismicInvestorInformation,
  redirects
): IInvestorInformation[] => {
  return (
    items?.allInvestor_informations?.edges.map(({ node: item }) => ({
      uid: item._meta.uid || null,
      title: parseString(item.title),
      annualReport: parseLink(item.annual_report),
      months: item.monthly.map((month) => ({
        month: month.item_month,
        consolidated: parseString(month.item_consolidated),
        yoyChange: parseString(month.item_yoychange),
        ytd: parseString(month.item_ytd),
        yoyTotal: parseString(month.item_yoytotal),
      })),
      quarters: item.quarters.map((quarter) => ({
        quarter: quarter.item_quarter,
        consolidated: parseLink(quarter.item_consolidated, redirects),
        press: parseLink(quarter.item_press, redirects),
        earnings: parseLink(quarter.item_earnings, redirects),
        webcast: parseLink(quarter.item_webcast, redirects),
      })),
      documents:
        item.documents?.map((document) => ({
          title: parseString(document.item_title),
          link: parseLink(document.item_link, redirects),
        })) ?? null,
    })) ?? null
  );
};
